import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Spoiler } from "../../components/Spoiler";
import { Callout } from "../../components/Callout";
export const _frontmatter = {
  "title": "Redux Selectors are Monads",
  "subtitle": "Using fp-ts to unlock advanced composition patterns.",
  "date": "2019-05-17T00:00:00.000Z",
  "layout": "post",
  "draft": false,
  "tags": ["javascript", "typescript", "redux", "functor", "monad", "reselect", "functional programming"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Redux Selectors (from reselect) are Monads.`}</p>
    <p>{`This unlocks some advanced composition patterns that we may have not considered before. Such as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` (a haskell-like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`do`}</code>{` notation for JavaScript with support for Typescript):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` userSelector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`postsSelector`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` userPostsSelector `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`selectorMonad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'user'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` userSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bindL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'posts'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`user`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`postsSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`done`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Here, we've composed two selectors that we created with reselect, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userSelector`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postsSelector`}</code>{`, into another selector, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userPostsSelector`}</code>{`, which selects a user and that user's posts, returning an object like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'bob'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <h2>{`The Monad interface`}</h2>
    <p>{`A monad is a wrapper around some value, which has a chain method that takes a callback, which takes the wrapped value, and returns another wrapper around some other value, and 'unwraps' it, leaving you with only one layer of wrapper. If we were to write out the type signature, it would look something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Wrapper`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Wrapper`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Wrapper`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Wrapper`}</code>{` can be any generic type. You've undoubtedly used this pattern before. Take a look at a (simplified) definition of a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Promise`}</code>{`'s `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`then`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Here we've just plugged `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Promise`}</code>{` into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Wrapper`}</code>{`, and you're probably familiar with this function already.`}</p>
    <p>{`The same is true for Arrays:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`flatMap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Although arrays name this function `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flatMap`}</code>{` and promises name this function `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`then`}</code>{`, they both have similar shapes!`}</p>
    <p>{`Selectors could also have a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` method which is shaped just like promise's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`then`}</code>{` and array's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flatMap`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Selector`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`This `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain/flatMap/then`}</code>{` method is the mark of a monad. Of course, there are a few more details which I'll describe later, but this one is the most important.`}</p>
    <p>{`In fp-ts, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Monad`}</code>{` interface is modelled as a `}<em parentName="p">{`typeclass`}</em>{` (This just means that the methods we need to implement exist on a separate object, instead of on the class itself). `}</p>
    <p>{`In our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selector`}</code>{` example, we showed selectors as a class with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` method. In reality, selectors are functions that are created outside of our control. Normally, If we wanted selectors to implement an interface, we'd have to wrap them with a thin wrapper that implements that interface, which gets clunky. With typeclasses, we simply define extra functions on a standalone object, freeing us from this restriction:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`s`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` State`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// chain isn't defined on an instance of Selector,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// instead it's on a completely separate object`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` selectorMonad `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
  chain`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    selector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Take a minute and try to implement this method. It might help to try and articulate in your head what the arguments are, and what the return type is, before thinking about an implementation.`}</p>
    <Spoiler label="Click to show chain" mdxType="Spoiler">
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`chain`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fa`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`f`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token constant"
              }}>{`A`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Selector`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`<`}</span>{`State`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// first, return a new "selector." This selector must return a value of type "B"`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token parameter"
            }}>{`state`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` State`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// use the "fa" selector to return an "a"`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` a `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`fa`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`state`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// give that "a" value to the f, which will give us a selector that returns a "B"`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` fb `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`f`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`a`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// give the state to the selector fb (which is a selector that returns a "B") `}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`fb`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`state`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre>{`
      `}</div>
    </Spoiler>
    <h1>{`Do`}</h1>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` works for `}<em parentName="p">{`all monads`}</em>{`, not just selectors, arrays, and promises. How does it know when to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flatMap`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`then`}</code>{`, or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{`? The first parameter to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` is an instance of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Monad`}</code>{` typeclass. This object contains all the logic.`}</p>
    <p>{`For example, if we wanted to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` with selectors, we'd take the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selectorMonad`}</code>{` typeclass instance and give it to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`selectorMonad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` returns a "builder," which allows us to incrementally bind the result of our selectors to identifiers that can be used by subsequent selectors. For example, lets take our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userSelector`}</code>{` and bind it to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"user"`}</code>{` identifier:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`selectorMonad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"user"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` userSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Remember, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userSelector`}</code>{` is still a normal selector function at this point. We could still use this like normal, say: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userSelector(state)`}</code>{`, which would return a user. Since functions don't have a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` method defined on them, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` uses the chain method defined on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selectorMonad`}</code>{`.`}</p>
    <p>{`Next, we can compose another selector that uses the output from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userSelector`}</code>{`. For our example, suppose we have a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postsSelector`}</code>{` which can select posts out of state for a particular user:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`postsSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`u`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Selector`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Post`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
  `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`user`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`state`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <Callout mdxType="Callout">
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`postsSelector`}</code>{` is curried, which just means that we'll invoke it like:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}>{`
      `}<pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`postsSelector`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`myUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`state`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// returns posts[] for this user`}</span></code></pre>{`
      `}</div>
      <p>{`This will make it easier to compose with other selectors, and to use in `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Do`}</code>{`.`}</p>
    </Callout>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bindL`}</code>{` method (short for `}<em parentName="p">{`bindLamda`}</em>{`) allows us to pass a function which takes all of the previously bound values as it's parameter. In our case, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`user`}</code>{` (which is the user returned from our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userSelector`}</code>{`) is already bound, so we can destructure it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`selectorMonad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"user"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` userSelector`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bindL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"posts"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` user `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`postsSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`From the function we pass to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bindL`}</code>{` we return a selector, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` will use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chain`}</code>{` method from our selectorMonad to `}<em parentName="p">{`compose`}</em>{` the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postsSelector`}</code>{` with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`userSelector`}</code>{`.  `}</p>
    <p>{`This lambda form of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bind`}</code>{` is incredibly powerful, as it lets us compose wrappers which depend on values that come from wrappers higher in the change.`}</p>
    <p>{`If this sounds familiar, it should. This was the impetus behind the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`async`}</code>{`/`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`await`}</code>{` syntax in Javascript. Consider this example where, instead of selecting users and posts from a redux store, we're fetching users and posts from a server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`async`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` user `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` posts `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchPosts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` posts`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Here, we're composing chains of promises, where later promises rely on the output of previous promises. Can you see how it's similar to the example where we composed selectors?`}</p>
    <p>{`We could also build a monad typeclass for promises and use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` to compose them:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`promiseMonad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'user'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bindL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'posts'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`user`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchPosts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`done`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <Callout mdxType="Callout">
      <p>{`Promises aren't technically monads, since they don't behave the monad laws (they are not referentially transparent). If that doesn't make sense to you right now, don't fret; we can still use them with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Do`}</code>{`, since they match the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Monad`}</code>{` shape.`}</p>
      <p>{`For future reference, it might make more sense to use `}<a parentName="p" {...{
          "href": ""
        }}></a>{` from fp-ts, which are just lazy promises.`}</p>
    </Callout>
    <p>{`Of course, Using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` to compose promises is a little more clumsy than the equivalent async/await (However you might finid that error-handling is much more straightforward). Why would we ever use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` then?`}</p>
    <p>{`Unlike async/await, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` works for `}<em parentName="p">{`all`}</em>{` monads, not just promises.`}</p>
    <p>{`Consider getting all posts from all users:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`arrayMonad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'user'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` usersArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bindL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'posts'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` user `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`postsArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Or getting a user's image from a user that's potentially null:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`maybeMonad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'user'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` maybeUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bindL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'image'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` user `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`maybeImage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Or even using multiple render props/hocs/hooks together:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ts"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-ts"
      }}><code parentName="pre" {...{
          "className": "language-ts"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`Do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`renderPropMonad`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'token'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` withAuthToken`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`bind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'currentUser'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` token `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withLoadable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`token`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` token`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` user `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ensureAdmin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`done`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` token`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` user `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Welcome`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <Callout mdxType="Callout">
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`Do`}</code>{` unlocks new syntax sugar for composing one of the most ubiquitous patterns in functional programming: monads. This is so common, in fact, that many functional programming languages provide syntax sugar for this very case. For example, there's `}<a parentName="p" {...{
          "href": "https://en.wikibooks.org/wiki/Haskell/do_notation"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`do`}</code></a>{` in haskell, and `}<a parentName="p" {...{
          "href": "https://docs.scala-lang.org/tutorials/FAQ/yield.html"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`for`}</code>{`-comprehensions`}</a>{` in scala.`}</p>
      <p>{`One day, I hope that Javascript can also have this type of syntax sugar. I've written a proposal for this syntax here: `}<a parentName="p" {...{
          "href": "https://github.com/pfgray/ecma-proposal-chainable-do-syntax#generalization"
        }}>{`https://github.com/pfgray/ecma-proposal-chainable-do-syntax#generalization`}</a>{`, and even a babel plugin which gets us most of the way there: `}<a parentName="p" {...{
          "href": "https://github.com/pfgray/babel-plugin-monadic-do"
        }}>{`https://github.com/pfgray/babel-plugin-monadic-do`}</a>{`.`}</p>
    </Callout>
    <p>{`One of the best features of fp-ts is that it uses typeclasses, which means you don't need to adjust anything about the way you're using selectors. You simply define the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`selectorMonad`}</code>{` object, and you can instantly start composing selectors that you made with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reselect`}</code>{` library (or any other library for that matter!).`}</p>
    <p>{`Async await was a great improvement (syntax-wise) for composing chains of promises. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Do`}</code>{` aims to bring that convenience to wealth of other types. You just need to be open to seeing those types through a different lens.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      